.product-card {
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 8px;
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth effect */
    background-color: white;
    text-align: center;
    margin-bottom: 15px;
    height: 450px;
  }
  
  .product-card:hover {
    transform: translateY(-5px); /* Slightly lift the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
    border-color: #007bff; /* Change border color on hover */
  }
  